
import App from './App.vue';

import { createApp } from 'vue';
import {Popup,Toast,List,PullRefresh,Cell,CellGroup,Button,Icon,Divider,TextEllipsis,Loading,Field,Empty,Swipe, SwipeItem , Image as VanImage,Col, Row,BackTop,Notify,Dialog,ActionSheet  } from 'vant';
import routers from './router/index'// 
import 'vant/lib/index.css';  //引入vant组件样式
import './styles/global.css'; //
import './styles/index.less';
import vPreviewImage from 'v-preview-image'

const app = createApp(App);
app.use(routers);
app.use(Toast);
app.use(List);
app.use(PullRefresh);
app.use(Cell);
app.use(CellGroup);
app.use(Button);
app.use(Icon);
app.use(Divider);
app.use(TextEllipsis);
app.use(Loading);
app.use(Empty);
app.use(Field);
app.mount('#app');
app.use(Swipe);
app.use(SwipeItem);
app.use(VanImage);
app.use(Col);
app.use(Row);
app.use(BackTop);
app.use(Notify);
app.use(Dialog);
app.use(ActionSheet );
app.use(Popup);
app.use(vPreviewImage)


// app.use(List);
// import router from './router';
// import { post, get, del, put } from './util/http';
// import api from './util/api';

// vue2使用prototype
// vue3使用globalProperties
// app.config.globalProperties.api = api;
// app.config.globalProperties.$get = get;
// app.config.globalProperties.$post = post;
// app.config.globalProperties.$del = del;
// app.config.globalProperties.$put = put;


 // 判断有没有token ，没有就返回登陆页
// router.beforeEach((to, from, next) => {
// 	if (to.meta.title) {
// 		document.title = to.meta.title;
// 	}
// 	if (to.matched.some((res) => res.meta.isLogin)) {
// 		const token = sessionStorage.getItem('Authorization');
// 		if (token) {
// 			next();
// 		} else {
// 			next({
// 				path: '/',
// 				query: {
// 					redirect: to.fullPath,
// 				},
// 			});
// 		}
// 	} else {
// 		next();
// 	}
// });
// app.mount('#app');

