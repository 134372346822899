import { createRouter, createWebHistory } from 'vue-router'

const routes = [
    {
        path: '/appsingledisplay', //单个应用app展示
        name: 'AppSingleDisplay',
        component: () => import('@/view/website/AppSingleDisplay.vue'),
    },
    {
        path: '/applicationdisplay', //多个app信息展示页面
        name: 'ApplicationDisplay',
        component: () => import('@/view/website/ApplicationDisplay.vue'),
    },
    {
        path: '/onlinepagelist',//线上【服务】页面
        name: 'OnlinePageList',
        component: () => import('@/view/onlinepage/OnlinePageList.vue'),
    },
    {
        path: '/onlinepagedetil', //服务页面详情页
        name: 'OnlinePageDetil',
        component: () => import('@/view/onlinepage/OnlinePageDetil.vue'),
    },
    {
        path: '/servicelist',  //联系客服页面
        name: 'CustServiceList',
        component: () => import('@/view/custservice/CustServiceList.vue'),
    },
    {
        path: '/helplist', //帮助中心
        name: 'HelpList',
        component: () => import('@/view/help/HelpList.vue'),
    },
    {
        path: '/helpdetail:title/:content', //帮助中心详情页
        name: 'HelpDetail',
        component: () => import('@/view/help/HelpDetail.vue'),
    },
    {
        path: '/rzsteplist', //软著申请流程主页面
        name: 'RzSteplist',
        component: () => import('@/view/rzstep/RzStepList.vue'),
    },
    {
        path: '/rzstep1',//软著申请流程详情
        name: 'RzStep1',
        component: () => import('@/view/rzstep/RzStep1'),
    },
    {
        path: '/feedbacklist',   
        name: 'FeedBackList',
        component: () => import('@/view/feedback/FeedBackList.vue'),
    },
    {
        path: '/addfeedback',       
        name: 'AddFeedBack',
        component: () => import('@/view/feedback/AddFeedBack.vue'),
    },
    {
        // 
        path: '/addrepresentation',    //申述新增
        name: 'AddRepresentation',
        component: () => import('@/view/appeal/AddRepresentation.vue'),
    },
    {
        path: '/jiaoyidetails', //商标交易详情页      
        name: 'JiaoYiDetails',
        component: () => import('@/view/sbtrading/JiaoYiDetailsNew.vue'),
    },
    {
        path: '/circuitone', //直流电路定义及基本物理量 
        name: 'CircuitOne',
        component: () => import('@/view/electricianpage/CircuitOne.vue'),
    },
    {
        path: '/ohmLawtwo', //欧姆定律   
        name: 'OhmLawTwo',
        component: () => import('@/view/electricianpage/OhmLawTwo.vue'),
    },
    {
        path: '/circuitcoectionthree', //电路的连接(串连、并连、混连)      
        name: 'CircuitCoectionThree',
        component: () => import('@/view/electricianpage/CircuitCoectionThree.vue'),
    },
    {
        path: '/electricalratefour', //电功和电功率     
        name: 'ElectricalRateFour',
        component: () => import('@/view/electricianpage/ElectricalRateFour.vue'),
    },
    {
        path: '/thermaleffectfive', //电流的热效应、短路      
        name: 'ThermalEffectFive',
        component: () => import('@/view/electricianpage/ThermalEffectFive.vue'),
    },
    {
        path: '/alternatingcurrentsix', //单项交流电路、交流电基本物理量、相位、初相位、相位差    
        name: 'AlternatingCurrentSix',
        component: () => import('@/view/electricianpage/AlternatingCurrentSix.vue'),
    },
    {
        path: '/threephaseseven', //三相交流电路    
        name: 'ThreePhaseSeven',
        component: () => import('@/view/electricianpage/ThreePhaseSeven.vue'),
    },
    {
        path: '/powercalculationeight', //单相、三相交流电路中功率计算公式     
        name: 'PowerCalculationEight',
        component: () => import('@/view/electricianpage/PowerCalculationEight.vue'),
    },
    {
        path: '/impedancecalculnine', //交流电路中电阻、电感、电容串并联的阻抗计算   
        name: 'ImpedanceCalculNine',
        component: () => import('@/view/electricianpage/ImpedanceCalculNine.vue'),
    },
    {
        path: '/electricalphyconten', //电学物理常数一览表     
        name: 'ElectricalPhyConTen',
        component: () => import('@/view/electricianpage/ElectricalPhyConTen.vue'),
    },
    {
        path: '/insulatingmaterialeleven', //常用绝缘材料性能      
        name: 'InsulatingMaterialEleven',
        component: () => import('@/view/electricianpage/InsulatingMaterialEleven.vue'),
    },
    {
        path: '/linecrosssectiontwelve', //线截面积与载流量的计算     
        name: 'LineCrossSectionTwelve',
        component: () => import('@/view/electricianpage/LineCrossSectionTwelve.vue'),
    },
    {
        path: '/calculationpage', //计算页面 -参数释义页面       
        name: 'ParameterInterPage',
        component: () => import('@/view/electricianpage/ParameterInterPage.vue'),
    },
    {
        path: '/colorecognition', //4色环电感识别页面       
        name: 'ColoRecognition',
        component: () => import('@/view/electricianpage/ColoRecognition.vue'),
    },
    {
        path: '/colorecognitions', //5-6色环电感识别页面       
        name: 'ColoRecognitions',
        component: () => import('@/view/electricianpage/ColoRecognitions.vue'),
    },
    {
        path: '/physicalcircuit', //电工图片展示与说明       
        name: 'PhysicalCircuit',
        component: () => import('@/view/physicalcircuit/physicalcircuit.vue'),
    },
    {
        path: '/threephasediagramsix', //三相电机接线图(6引线)  
        name: 'ThreePhaseDiagramSix',
        component: () => import('@/view/electricianpage/ThreePhaseDiagramSix.vue'),
    },
    {
        path: '/threephasediagramnine', //三相电机接线图(9引线)  
        name: 'ThreePhaseDiagramNine',
        component: () => import('@/view/electricianpage/ThreePhaseDiagramNine.vue'),
    },
    {
        path: '/threephasediagramtwelve', //三相电机接线图(12引线)  
        name: 'ThreePhaseDiagramTwelve',
        component: () => import('@/view/electricianpage/ThreePhaseDiagramTwelve.vue'),
    },
    {
        path: '/inductancecodepage', //电感代码  
        name: 'InductanceCodePage',
        component: () => import('@/view/electricianpage/InductanceCodePage.vue'),
    },
    {
        path: '/', //应用官网appwebsite
        name: 'AppWebsite',
        component: () => import('@/view/appwebsite/AppWebsite.vue'),
    }
    

];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

export default router