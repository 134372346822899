<template>
  <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
// import HelpFeedBack from './view/helpFeedBack.vue'
// // import HelpCenter from './components/HelpCenter.vue';

// export default {
//   name: 'App',
//   components: {
//     // HelpCenter,
//     HelpFeedBack,
//   }
// }
</script>

<style>
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */
body {
  font-size: 16px;
  background-color: #FFFFFF;
  -webkit-font-smoothing: antialiased;
}




</style>
